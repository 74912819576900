import PropTypes from 'prop-types';
import React from 'react';

import {
  STORAGE_SETTINGS,
  STORAGE_NAVBAR,
  STORAGE_PRODUCTS,
  STORAGE_SLIDER,
  STORAGE_CONTACTS,
  SITE_INFO_SETTINGS,
  SITE_INFO_NAVBAR,
  SITE_INFO_CONTACTS,
  SITE_INFO_SLIDER,
} from '../helpers/constants';
import { isBrowser } from '../helpers/environment';
import { prepareCategoryTree } from '../helpers/catalog';
import {
  settingsProps, navbarProps, productProps, sliderProps, catalogProps, apiContactProps,
} from '../helpers/propTypes';
import Api from '../helpers/api';
import Cart from '../helpers/cart';
import IndexPage from '../components/pages/index/Index';
import redirectDomain from '../helpers/redirectDomain';
import Storage from '../helpers/storage';

export default class IndexPageWrapper extends React.Component {
    static propTypes = {
      navbar: navbarProps.isRequired,
      slider: sliderProps,
      settings: settingsProps.isRequired,
      products: PropTypes.arrayOf(productProps).isRequired,
      catalog: catalogProps.isRequired,
      contacts: apiContactProps.isRequired,
    };

    static defaultProps = {
      slider: {},
    };

    componentDidMount() {
      const {
        navbar, slider, products, settings, contacts,
      } = this.props;

      Storage.set(STORAGE_NAVBAR, navbar);
      Storage.set(STORAGE_PRODUCTS, products);
      Storage.set(STORAGE_SETTINGS, settings);
      Storage.set(STORAGE_SLIDER, slider);
      Storage.set(STORAGE_CONTACTS, contacts);
      Cart.actualizeProducts(products);
    }

    static getInitialProps = async ({ res, req, asPath }) => {
      const api = new Api({ domain: req ? req.headers.host : null });
      const { isNewQRMenuAvailable, hash } = await api.getQrMenuStatus();
      if (isNewQRMenuAvailable) {
        res.writeHead(302, { Location: `https://menu.ps.me/${hash}` });
        res.end();
      }

      if (isBrowser()) {
        const navbar = Storage.get(STORAGE_NAVBAR);
        const products = Storage.get(STORAGE_PRODUCTS);
        const settings = Storage.get(STORAGE_SETTINGS);
        const slider = Storage.get(STORAGE_SLIDER);
        const contacts = Storage.get(STORAGE_CONTACTS);

        let catalog = [];
        if (products) {
          catalog = prepareCategoryTree(products);
        }

        if (navbar && slider && products && settings && catalog) {
          return {
            navbar,
            slider,
            products,
            settings,
            catalog,
            contacts,
          };
        }
      }

      const [siteInfo, products] = await Promise.all([
        api.getSiteInfo([SITE_INFO_CONTACTS, SITE_INFO_SETTINGS, SITE_INFO_NAVBAR, SITE_INFO_SLIDER]),
        api.getProducts(),
      ]);

      const catalog = prepareCategoryTree(products);

      if (!isBrowser()) {
        redirectDomain({
          settings: siteInfo.settings,
          host: req.headers.host,
          asPath,
          res,
        });
      }

      return {
        ...siteInfo,
        catalog,
        products,
      };
    };

    render() {
      return <IndexPage {...this.props} />;
    }
}
